<template>
      
  <div class="zhanList">
    <header class="banner">
      <img
        src="http://www.bowuguan.com/upload/e44b949d2736a79f/b0e28853f40717a1.png"
        alt=""
      />
    </header>
    <!-- <aside class="action">
      <span @click="$router.push('/activity')">博物活动</span>  &nbsp;
      <img src="../assets/img/InforCenter/juxing14.png" alt="" />
      &nbsp;艺术展览
    </aside> -->
    <div class="Leftaction">
    <mainBox></mainBox>

    </div>
    <div :class="Scales ? 'Scales' : ''">

    <div class="list">
      <ul class="listed">
        <li :class="quans === '0' ? 'actives' : 'null'" @click="activ('0')">
          全部
        </li>
        <li :class="quans === '1' ? 'actives' : 'null'" @click="activ('1')">
          未开始
        </li>
        <li :class="quans === '2' ? 'actives' : 'null'" @click="activ('2')">
          进行中
        </li>
        <li :class="quans === '3' ? 'actives' : 'null'" @click="activ('3')">
          已结束
        </li>
      </ul>
    </div>
    <aside class="action2">
      <div class="dev">
        <div class="inputs">
          <input type="text" v-model="value" />
          <div class="btn" @click="sreach"></div>
        </div>
      </div>
    </aside>
    <div class="imageList" v-if="outList.length !=0 ">
      <div
        class="imgs"
         :class="(key+1) % 5 == 1 ? '' : 'marLeft'"

        v-for="(item, key) in outList"
        :key="key"
      >
      <div>
        <div class="tupian" @click="skipDetails(item)">
          <img
            :src="require('../assets/img/book/' + item.ceimg)"
            alt=""
            class="leftImg"
          />
          <img :src="item.images" alt="" class="big rightImg" />
        </div>

        <div class="leftImgText">
          <div class="detailTitle">
            {{ item.introduce }}
          </div>
          <div class="titleName">
            {{ item.name }}
          </div>
        </div>
        <div class="tou">
          <span>
            {{ item.hdstatus }}
          </span>
        </div>
        <div class="listeds">
          <div>
            <span class="zhanLanTime"> 展览时间: </span>
            <span class="zhi">
              <!-- {{item.create_time}}{{item.end_time}} -->
              {{ item.start_time | time }}至{{ item.end_time | time }}
            </span>
          </div>
          <div>
              <div
              class="Titright"
              v-if="item.like_status === '2' || !token"
              @click="shoucang(key)"
            >
              <img src="../assets/collect2.png" alt="" />
            </div>
            <div
              class="Titright"
              v-if="item.like_status === '1' "
              @click="shoucang(key)"
            >
              <img src="../assets/sc.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <div class="imageList" v-else>
        暂无匹配项
    </div>
    <div class="page">
      <Pagetion :total="activs" :pageSize="12" @ClickChange="handleCurrentChange"></Pagetion>
    </div>
    </div>

        <Footer></Footer>
  </div>
</template>

<script>
import { fetch, post } from '../api/https';
import Footer from "../components/footer";
import Pagetion from '../components/pagetion/pagetion.vue';
import mainBox from "@/components/main.vue";
import moment from 'moment'
export default {
  data() {
    return {
      quans: "0",
      outList: [],
      page: 1,
      value: "",
      status: 0,
    Scales:false,
      activs: 1,
      token:localStorage.getItem('token') || ''
    };
  },
  filters: {
    time(num){
      return moment(1000*parseInt(num)).format("YYYY-MM-DD")
      
    }
  },
  components: {
    Footer,
    Pagetion,
    mainBox
  },
  created() {},
  mounted() {
    this.requstOut();
    document.title = '展览列表'
  },
  methods: {
    shoucang(key){
      if(localStorage.getItem('token')){
        var obj = {
              token:localStorage.getItem('token'),
              source:'pc',
              id:this.outList[key].id
        }
          if(this.outList[key].like_status == '1'){
                  this.outList[key].like_status = '2'
                  //调用收藏接口 ========== 取消收藏
                  
                  post('/activity_like',obj).then((res) => {
                    this.showPopupMessage(res.msg,true)
                  })
                  }else{
                  this.outList[key].like_status = '1'
                  //调用收藏接口 ========== 收藏
                  post('/activity_like',obj).then((res) => {
                    this.showPopupMessage(res.msg,true)
                  })
          }
      }else{
        this.showPopupMessage('请先登录',false)
      }
    },
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
      this.page = val;
      this.requstOut();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    sreach() {
      this.activs = 1;
      this.status = 0;
      this.page = 1;
      this.requstOut();
    },
    activ(val) {
      this.quans = val;
      this.status = val;
      this.page = 1;
      this.value = "";
      this.requstOut();
    },
    requstOut() {
      fetch("/activity_list", {
        status: this.status,
        page: this.page,
        pagesize: 12,
        cate_id: 2,
        name: this.value,
        source: "pc",
        token:localStorage.getItem("token") || ''
      })
        .then((res) => {
          this.outList = res.data.data;
          this.activs = res.data.total_count;
          this.outList.forEach((item, key) => {
            var num = (key % 8) + 1;
            var str = `${num}.png`;
            item.ceimg = str;
          });
          this.outList.forEach((item,key)=>{
            item.create_time = moment(1000*parseInt(item.create_time)).format(
              "YYYY-MM-DD"
            )
          })
        })
        .catch((err) => {});
    },
    skipDetails(item) {
      this.$router.push(`/Introduction?id=${item.id}`);
    },
  },
};
</script>

<style scoped>
.zhanList {
  width: 100%;
  height: auto;
}
.banner {
  width: 100%;
  height: 10px;
}
.banner img {
  width: 0;
  height: 0%;
}
.action {
  display: block;
  max-width: 1400px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  color: #1a1a1a;
  box-sizing: border-box;
  margin-top: 49px;
  margin-bottom: 50px;
}
.listed {
  margin: 0 auto;
}
.list {
  max-width: 1400px;
  margin: 0 auto;
}
.listed li {
  cursor: pointer;
  height: 40px;
  width: 125px;
  text-align: center;
  line-height: 40px;
  float: left;
  margin-right: 20px;
}
.null {
  background: url("../assets/img/book/uncheck.png");
  background-size: 125px 40px;
  color: #666666;
}
.actives {
  background: url("../assets/img/book/checked.png");
  background-size: 125px 40px;
  color: white;
}
.imageList {
  width: 1400px;
  margin: 0 auto;
  padding-top: 38px;
  height: auto;
  padding-bottom: 45px;
  min-height: 500px;
  /* padding-left: 50px; */
}
.imgs {
  float: left;
  width: 328px;
  height: 507px;
  position: relative;
  float: left;
  transition: 1s;
}
.marLeft{
  margin-left: 29px;

}
.imgs:hover{
  transform: scale(1.1);

}
.big {
  position: absolute;
  top: 0;
  right: 0;
  width: 198px;
  height: 449px;
}
.leftImg {
  width: 130px;
  height: 449px;
  float: left;
}
.leftImgText {
  position: absolute;
  top: 0;
  left: 0;
  width: 130px;
  height: 100%;
  color: white;
  box-sizing: border-box;
  padding-top: 30px;
  padding-left: 35px;
  padding-right: 35px;
}
.titleName {
  font-size: 20px;
  width: 25px;
  float: right;
}
.titleName:hover{
  cursor: pointer;
}
.detailTitle {
  width: 20px;
  float: left;
  font-size: 15px;
}
.action2 {
  display: block;
  max-width: 1400px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  color: #1a1a1a;
  line-height: 42px;
  height: 42px;
}
.action2 .dev {
  width: 500px;
  float: right;
}
.inputs {
  background: url("../assets/img/book/search.png") no-repeat;
  background-size: 340px 40px;
  width: 340px;
  height: 40px;
  padding-top: 10px;
  float: right;
  position: relative;
}
.inputs input {
  position: absolute;
  left: 0;
  top: 0;
  height: 30px;
  border: none;
  padding: 10px;
  padding-left: 15px;
  height: 20px;
  background: none;
  outline: none;
  width: 80% !important;

}
.btn {
  background: url("../assets/img/book/searchBtn.png");
  background-size: 16px;
  width: 16px;
  height: 16px;
  float: right;
  text-align: center;
  line-height: 40px;
  margin-top: 10px;
  color: white;
  cursor: pointer;
  position: absolute;
  top: 7%;
  right: 13px;
}
.tou {
  width: 60px;
  height: 22px;
  background-color: #4c733c;
  font-size: 12px;
  text-align: center;
  line-height: 22px;
  color: white;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 11px;
}
.page {
  width: 1400px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: space-between;
  text-align: center;
  padding-top: 45px;
  padding-bottom: 58px;
}
el-pagination {
  position: absolute;
  right: 30%;
  top: 20px;
}
>>> .el-pagination__total {
  /* padding-top: 7px; */
  margin-left: 5px;font-weight: 600;
  /* font-size: 15px; */
  transform: scale(1.19);
}
.tupian {
  height: 449px;
}

.listeds {
  height: 14px;
  display: flex;
  justify-content: space-between;
  padding-top: 14px;
  padding-bottom: 30px;
}
.zhanLanTime {
  color: #999999;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  
font-weight: 400;
}
.zhi {
  font-size: 14px;
  color: #666666;
  
font-weight: 400;
}
@media screen and  (max-width:1439px){
  .imageList{
    width: 1366px;
    padding-left: 14px;
    box-sizing: border-box;
  }
  .marLeft{
    margin-left: 10px;
  }
  .Leftaction{
    margin-left: -66px;
  }
  .action2{
    width: 1366px;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .listed{
    padding-left: 15px;
  }
  .page{
    width: 1366px;
  }
}
</style>